import React from "react";
import ReactDOM from "react-dom";
import { TranslatedApp } from "./App";
import "./i18n";

ReactDOM.render(
    <TranslatedApp />,
    document.getElementById("app")
);

if (module.hot) {
    module.hot.accept();
}
