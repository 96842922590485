import * as React from "react";
import PropTypes from "prop-types";

export class Link extends React.Component {
    onHandler(e) {
        const elCordinats = document.getElementById(this.props.goToId).getBoundingClientRect();
        const y = elCordinats.y - e.target.parentElement.offsetHeight - 30 + window.pageYOffset;

        window.scrollTo(0, y);
    };

    render() {
        const { mdf, text } = this.props;

        return (
            <div className={`sv-trans__link sv-trans__link--${mdf}`} onClick={this.onHandler.bind(this)}>
                {text}
            </div>
        );
    };
};

Link.propTypes = {
    goToId: PropTypes.string,
    text: PropTypes.string,
    mdf: PropTypes.string
};