import React from "react";
import PropTypes from "prop-types";
import { SecondaryHeader } from "../";

export function ContactSection(props) {
    const mainClassName = "sv-trans__contact-section";
    const itemClassName = `${mainClassName}-item`;

    return (
        <section className={mainClassName}>
            <SecondaryHeader text={props.title} />
            {
                props.contact ? (
                    <span className={`${mainClassName}-contact`}>
                        {props.contact}
                    </span>
                ) : <></>
            }
            {
                props.phone ? (
                    <div className={itemClassName}>
                        <span className={`${itemClassName}-label`}>
                            {props.phoneLabel}
                        </span>
                        <a className={`${itemClassName}-text`} href={`tel:${props.phone}`}>
                            {props.phone}
                        </a>
                    </div>
                ) : <></>
            }
            {
                props.email ? (
                    <div className={itemClassName}>
                        <span className={`${itemClassName}-label`}>
                            {props.emailLabel}
                        </span>
                        <a className={`${itemClassName}-text`} href={`mailto:${props.email}`}>
                            {props.email}
                        </a>
                    </div>
                ) : <></>
            }
        </section>
    );
};

ContactSection.propTypes = {
    title: PropTypes.string,
    contact: PropTypes.string,
    phoneLabel: PropTypes.string,
    phone: PropTypes.string,
    emailLabel: PropTypes.string,
    email: PropTypes.string
};
