import React from "react";
import PropTypes from "prop-types";

function BaseHeader(props) {
    const mainClassName = "sv-trans__header";

    return (
        <header className={`${mainClassName} ${mainClassName}--${props.mdf}`}>
        </header>
    );
}

BaseHeader.propTypes = {
    mdf: PropTypes.string
};

Header.propTypes = {
    mdf: PropTypes.string,
    text: PropTypes.string
};

SecondaryHeader.propTypes = {
    mdf: PropTypes.string,
    text: PropTypes.string
};

export function Header(props) {
    return (
        <header className="sv-trans__header sv-trans__header--default">
            <h4>{props.text}</h4>
        </header>
    );
};

export function SecondaryHeader(props) {
    return (
        <p className="sv-trans__header sv-trans__header--secondary">
            {props.text}
        </p>
    );
};
