import React from "react";
import PropTypes from "prop-types";

export function ListItem(props) {
    return (
        <li className="sv-trans__list-item">{props.text}</li>
    );
}

ListItem.propTypes = {
    text: PropTypes.string
};
