import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// todo: we might add browser language detector here later using i18next-browser-languagedetector
i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false
        },
        lng: "en", // "bg" | "en"
        resources: {
            bg: {
                translation: {
                    navbar: {
                        links: {
                            home: "Начало",
                            forUs: "За нас",
                            services: "Услуги",
                            contacts: "Контакти"
                        }
                    },
                    forUs: {
                        title: "За нас",
                        text: "Предлагаме международен транспорт в цяла Европа. СВ ТРАНС БГ ЕООД е създадена " +
                            "през 2017г. от млад и енергичен екип търсещ и използващ " +
                            "технологиите и иновациите в транспорта за намиране на най-прекия и лек път до " +
                            "успеха. Автомобилният парк е от камиони Евро 5 и Евро 6 за по ниски въглеродни " +
                            "емисии. Фирмата извършва транспортна дейност на товари в Европа с бордови " +
                            "ремаркета. Фирмата разполага със гаражна площ и сервиз за обслужване на камионите " +
                            "и ремаркетата. СВ ТРАНС БГ ЕООД е признат партньор на водещи български и " +
                            "чуждестранни фирми. Мотото на фирмата е 'Винаги навсякъде!'."
                    },
                    services: {
                        title: "Услуги",
                        listTitle: "Фирмата предлага:",
                        list: [
                            "Международен транспорт на бордови товари от и за България за и от цяла Европа " +
                                "(без ADR опасни товари);",
                            "Вътрешни курсове в Европа; България, Унгария, Австрия, Хърватия, Словения и Босна и " +
                                "Херцеговина;",
                            "Диспонентски услуги на трети фирми;",
                            "Подаване на товари, адреси, отчетно записване и следене на товарите в таблица;",
                            "Изчисляване на разхода на гориво;",
                            "Kомуникация с всички страни взимащи участие в логистиката;",
                            "Съдействие при проблеми и намиране на сервизи;",
                            "Осигуряване на товари от и за Европа;"
                        ]
                    },
                    contacts: {
                        titles: {
                            main: "Контакти",
                            office: "Офис:",
                            transport: "Транспотрен отдел:",
                            financial: "Финансово-счетоводни дейности:"
                        },
                        managerName: "Стефан Вълев",
                        transportDepartmentContact: "Стефан Стефанов",
                        financialAccountantContact: "Георгина Тончева",
                        labels: {
                            manager: "Управител:",
                            phone: "Телефон:",
                            email: "Ел. поща:"
                        },
                        location: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1749.7733067904287!2d26.526792859043596!3d42.475473580992805!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a6355c443f79eb%3A0xd581f2b8bd2fefa5!2z0KHQkiDQotCg0JDQndChINCR0JMg0JXQntCe0JQ!5e0!3m2!1sbg!2sbg!4v1580944026135!5m2!1sbg!2sbg"
                    }
                }
            },
            en: {
                translation: {
                    navbar: {
                        links: {
                            home: "Home",
                            forUs: "For us",
                            services: "Services",
                            contacts: "Contacts"
                        }
                    },
                    forUs: {
                        title: "For us",
                        text: "We offer international transport across Europe. SV TRANS BG Ltd. was founded in " +
                            "2017 from young and energetic team looking for and " +
                            "using latest technologies and innovations in transport for finding the shortest and " +
                            "the easiest way to success. The auto park consists of Euro 5 and Euro 6 trucks for " +
                            "lower carbon emissions. The company offers transport services of goods in Europe " +
                            "using flatbed trailers, also it has garage area and auto-service for trucks and " +
                            "trailers. SV TRANS BG Ltd. is a recognized partner of leading Bulgarian and foreign " +
                            "companies. The motto of the company is 'Always and everywhere!'."
                    },
                    services: {
                        title: "Services",
                        listTitle: "The company offers:",
                        list: [
                            "International transport of cargo from and to Bulgaria to and from all over Europe " +
                                "(without ADR dangerous goods);",
                            "Domestic courses in Europe; Bulgaria, Hungary, Austria, Croatia, Slovenia and Bosnia " +
                                "and Herzegovina;",
                            "Dispensary services to third parties;",
                            "Submission of goods, addresses, reporting and tracking of goods in a table;",
                            "Calculation of fuel consumption;",
                            "Communication with all parties involved in the logistics;",
                            "Assistance in case of problems and finding service stations;",
                            "Assuring cargo from and to Europe;"
                        ]
                    },
                    contacts: {
                        titles: {
                            main: "Contacts",
                            office: "Office:",
                            transport: "Transport department:",
                            financial: "Financial accounting department:"
                        },
                        managerName: "Stefan Valev",
                        transportDepartmentContact: "Stefan Stefanov",
                        financialAccountantContact: "Georgina Toncheva",
                        labels: {
                            manager: "Manager:",
                            phone: "Phone:",
                            email: "Email:"
                        },
                        location: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d874.8876191970495!2d26.52766683868121!3d42.47540449660077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a6355c443f79eb%3A0xd581f2b8bd2fefa5!2z0KHQkiDQotCg0JDQndChINCR0JMg0JXQntCe0JQ!5e0!3m2!1sen!2sbg!4v1580944209994!5m2!1sen!2sbg"
                    }
                }
            }
        }
    });

export default i18n;