export const constants = {
    office: {
        // phone: "+359890520002",
        email: "office@svtransbg.com"
    },
    transport: {
        phone: "+359877806081",
        email: "transport@svtransbg.com"
    },
    financial: {
        phone: "+359894281888",
        email: "accounting@svtransbg.com"
    }
};
