import React from "react";
import PropTypes from "prop-types";
import { Image } from "../";

export function LanguageChoice(props) {
    return (
        <div
            className={`sv-trans__language-choice sv-trans__language-choice--${props.mdf}`}
            onClick={props.changeLanguage}>
            <Image
                imgLink={props.imgLink}
                width="30px" height="20px" mdf="language-choice"
                alt={`image-${props.code}`} />
            <span className="sv-trans__language-choice-name">{props.code}</span>
        </div>
    );
}

LanguageChoice.propTypes = {
    changeLanguage: PropTypes.func,
    code: PropTypes.string,
    imgLink: PropTypes.string,
    mdf: PropTypes.string.isRequired
};
