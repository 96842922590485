import svTransLogoBg from "./svTransLogoBg.png";
import svTransLogoEn from "./svTransLogoEn.png";
import svTransBannerBg from "./svTransBannerBg.png";
import svTransBannerEn from "./svTransBannerEn.png";
import firstCarouselItem from "./firstCarouselItem.png";
import secondCarouselItem from "./secondCarouselItem.png";
import thirdCarouselItem from "./thirdCarouselItem.png";
import flagBG from "./flagBG.png";
import flagGB from "./flagGB.png";
import svTrans from "./svTrans.png";
import svTransServices from "./svTransServices.png";
import iconMenu from "./iconMenu.svg";

const baseCarouselItems = [
    firstCarouselItem,
    secondCarouselItem,
    thirdCarouselItem
];

export const imgs = {
    logo: {
        bg: svTransLogoBg,
        en: svTransLogoEn
    },
    carouselItems: {
        bg: [svTransBannerBg].concat(baseCarouselItems),
        en: [svTransBannerEn].concat(baseCarouselItems)
    },
    flags: {
        bg: flagBG,
        en: flagGB
    },
    svTrans: svTrans,
    svTransServices,
    icons: {
        menu: iconMenu
    }
};