import React from "react";
import PropTypes from "prop-types";
import { Header } from "../";

function BaseInfoSection(props) {
    const mainClassName = "sv-trans__info-section";

    return (
        <section className={`${mainClassName} ${mainClassName}--${props.mdf}`}>
            <Header text={props.title}/>
            <p className={`${mainClassName}-text`}>{props.text}</p>
        </section>
    );
};

BaseInfoSection.propTypes = {
    mdf: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string
};

export function InfoSection(props) {
    return (
        <BaseInfoSection mdf="default" {...props} />
    );
};

export function FollowUpInfoSection(props) {
    return (
        <BaseInfoSection mdf="follow-up" {...props} />
    );
}