import React from "react";
import PropTypes from "prop-types";

export function Logo(props) {
    const style = {
        backgroundImage: `url(${props.logo})`
    };
    return (
        <span
            className="sv-trans__logo"
            style={style}
        ></span>
    );
}

Logo.propTypes = {
    logo: PropTypes.string
};