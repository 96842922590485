import React from "react";
import PropTypes from "prop-types";

export function GoogleMaps(props) {
    return (
        <iframe
            src={props.mapsLink}
            width={props.width}
            height={props.height}
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            className="sv-trans__maps"
            title="SV Trans location">
        </iframe>
    );
}

GoogleMaps.propTypes = {
    mapsLink: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
};