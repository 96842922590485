import React from "react";
import PropTypes from "prop-types";

export function Image(props) {
    return (
        <img
            className={`sv-trans__image sv-trans__image--${props.mdf}`}
            src={props.imgLink}
            width={props.width}
            height={props.height}
            alt={props.alt}
        ></img>
    );
}

Image.propTypes = {
    mdf: PropTypes.string,
    imgLink: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    alt: PropTypes.string
};
