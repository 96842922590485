import React from "react";
import PropTypes from "prop-types";
import * as SCMP from "../components";
import Carousel from "react-bootstrap/Carousel";
import { constants } from "../../constants";
import { imgs } from "../../imgs";

export class PageBlock extends React.Component {
    render() {
        return (
            <div className="sv-trans__page">
                <Carousel as={"section"} className="sv-trans__page-carousel">
                    {imgs.carouselItems[this.props.i18n.language].map((image, index) =>
                        <Carousel.Item key={index}>
                            <SCMP.Image imgLink={image} mdf="carousel" alt={`image-carousel-${index}`} />
                        </Carousel.Item>
                    )}
                </Carousel>
                <section id="section-for-us" className="sv-trans__page-info-section">
                    <SCMP.Image imgLink={imgs.svTrans} mdf="followed-by-info-section" alt="truck-image" />
                    <SCMP.FollowUpInfoSection
                        title={this.props.t("forUs.title")}
                        text={this.props.t("forUs.text")}
                    />
                </section>
                <section id="section-services" className="sv-trans__page-services-section">
                    <div className="sv-trans__page-services-details">
                        <SCMP.Header text={this.props.t("services.title")} />
                        <p>{this.props.t("services.listTitle")}</p>
                        <ul className="sv-trans__page-sevices-list">
                            {this.props.t("services.list", { returnObjects: true }).map((text, index) =>
                                <SCMP.ListItem key={`service-list-${index}`} text={text} />
                            )}
                        </ul>
                    </div>
                    <SCMP.Image imgLink={imgs.svTransServices} mdf="followed-by-info-section" alt="truck-image" />
                </section>
                <section id="section-contacts" className="sv-trans__page-contact-section">
                    <SCMP.GoogleMaps
                        width="400"
                        height="300"
                        mapsLink={this.props.t("contacts.location")}
                    />
                    <div className="sv-trans__page-contact-details">
                        <SCMP.Header text={this.props.t("contacts.titles.main")} />
                        <address className="sv-trans__page-contact-items">
                            <div className="sv-trans__page-contact-item">
                                <span className="sv-trans__page-contact-item-label">
                                    {this.props.t("contacts.labels.manager")} {this.props.t("contacts.managerName")}
                                </span>
                            </div>
                            <SCMP.ContactSection
                                title={this.props.t("contacts.titles.office")}
                                phoneLabel={this.props.t("contacts.labels.phone")}
                                phone={constants.office.phone}
                                emailLabel={this.props.t("contacts.labels.email")}
                                email={constants.office.email}
                            />
                            <SCMP.ContactSection
                                title={this.props.t("contacts.titles.transport")}
                                contact={this.props.t("contacts.transportDepartmentContact")}
                                phoneLabel={this.props.t("contacts.labels.phone")}
                                phone={constants.transport.phone}
                                emailLabel={this.props.t("contacts.labels.email")}
                                email={constants.transport.email}
                            />
                            <SCMP.ContactSection
                                title={this.props.t("contacts.titles.financial")}
                                contact={this.props.t("contacts.financialAccountantContact")}
                                phoneLabel={this.props.t("contacts.labels.phone")}
                                phone={constants.financial.phone}
                                emailLabel={this.props.t("contacts.labels.email")}
                                email={constants.financial.email}
                            />
                        </address>
                        <div className="fb-page sv-trans__page-contact-fb-page"
                            data-href="https://www.facebook.com/svtransbgLtd/"
                            data-tabs="timeline"
                            data-width=""
                            data-height="70px"
                            data-small-header="false"
                            data-adapt-container-width="true"
                            data-hide-cover="false"
                            data-show-facepile="true">
                            <blockquote
                                cite="https://www.facebook.com/svtransbgLtd/"
                                className="fb-xfbml-parse-ignore">
                                <a href="https://www.facebook.com/svtransbgLtd/">
                                    SV TRANS BG Ltd.
                                </a>
                            </blockquote>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

PageBlock.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
};