import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { Image, LanguageChoice, Link, Logo } from "./blocks/components";
import { PageBlock } from "./blocks/PageBlock/PageBlock";
import "./less/app.less";
import { imgs } from "./imgs";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.changeLanguageToEn = () => { this.props.i18n.changeLanguage("en"); };
        this.changeLanguageToBg = () => { this.props.i18n.changeLanguage("bg"); };
    };

    render() {
        // skatelieva: todo: create helper which will generate nav props data
        const navProps = [{
            id: "main-content",
            text: this.props.t("navbar.links.home")
        }, {
            id: "section-for-us",
            text: this.props.t("navbar.links.forUs")
        }, {
            id: "section-services",
            text: this.props.t("navbar.links.services")
        }, {
            id: "section-contacts",
            text: this.props.t("navbar.links.contacts")
        }];

        return (
            <div id="main-content" className="sv-trans">
                <div className="sv-trans__nav-wrapper">
                    <nav className="sv-trans__nav-bar">
                        <Dropdown className="sv-trans__nav-bar-dropdown">
                            <Dropdown.Toggle variant="transparent" className="sv-trans__nav-bar-dropdown-trigger">
                                <Image height="39px" width="39px" imgLink={imgs.icons.menu} mdf="menu" alt="menu" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="sv-trans__nav-bar-dropdown-menu">
                                {navProps.map((prop, index) =>
                                    <Dropdown.Item key={index}>
                                        <Link
                                            goToId={prop.id}
                                            key={index}
                                            text={prop.text}
                                            mdf="nav-dropdown" />
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Logo logo={imgs.logo[this.props.i18n.language]} />
                        {navProps.map((prop, index) =>
                            <Link
                                goToId={prop.id}
                                key={index}
                                text={prop.text}
                                mdf="nav" />
                        )}
                        <div className="sv-trans__nav-bar-language-picker">
                            <LanguageChoice
                                changeLanguage={this.changeLanguageToEn}
                                code="en"
                                imgLink={imgs.flags.en}
                                mdf="navigation-bar"
                            />
                            <LanguageChoice
                                changeLanguage={this.changeLanguageToBg}
                                code="bg"
                                imgLink={imgs.flags.bg}
                                mdf="navigation-bar"
                            />
                        </div>
                    </nav>
                </div>
                <div className="sv-trans__main-content">
                    <PageBlock t={this.props.t} i18n={this.props.i18n} />
                </div>

            </div>
        );
    }
}

App.propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
};

export const TranslatedApp = withTranslation()(App);